import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "~/config/axios";

export const getSalesRepList = () => {
  return axios.get(API_URL + `/CustomerOrder/SalesRepList`, {
    headers: authHeader(),
  });
};

export const getShippingList = () => {
  return axios.get(API_URL + `/CustomerOrder/ShippedToList`, {
    headers: authHeader(),
  });
};

export const getBillingList = () => {
  return axios.get(API_URL + `/CustomerOrder/BilledToList`, {
    headers: authHeader(),
  });
};

export const getCustomerCart = () => {
  return axios.get(API_URL + `/CustomerOrder/GetCustomerCartData`, {
    headers: authHeader(),
  });
};

export const UpdateCustomerCart = (data: any) => {
  return axios.post(API_URL + "/CustomerOrder/CustomerCartUpdate", data, {
    headers: authHeader(),
  });
};

export const deleteCustomerCartProduct = (data: any) => {
  return axios.post(
    API_URL + "/CustomerOrder/DeleteCustomerCartProduct",
    data,
    {
      headers: authHeader(),
    }
  );
};

export const MakeOrderPayment = (data: any) => {
  return axios.put(
    API_URL + "/CustomerOrder/MakeOrderPayment",
    data,
    {
      headers: authHeader(),
    }
  );
};

export const CreateOrderForPayment = (data: any) => {
  return axios.post(
    API_URL + "/Order/Create",
    data,
    {
      headers: authHeader(),
    }
  );
};


export const DeleteCustomerOrder = (id : string) => {
  return axios.delete(API_URL + `/CustomerOrder?id=${id}`, {
    headers: authHeader(),
  });
};


export const deleteAllCustomerCartProduct = () => {
  return axios.post(API_URL + "/CustomerOrder/DeleteAllCustomerCartProduct", {
    headers: authHeader(),
  });
};

export const getTrackingDetails = (id : number) => {
  return axios.get(API_URL + `/CustomerOrder/GetOrderTrackingDetails?id=${id}`, {
    headers: authHeader(),
  });
};

export const TrackShippment = (data : any) => {
  return axios.post(API_URL + `/UPSAPI/TrackShipment`, data,
  {
    headers: authHeader(),
  });
};

export const getManufacturers = async () => {
  const res = await axios.get(API_URL + `/CustomerOrder/ManufacturerList`, {
    headers: authHeader(),
  });
  return res.data;
};

export const ValidateCC = (data: any) => {
  return axios.put(
    API_URL + "/CustomerOrder/MakeOrderValidatePaymentCC",
    data,
    {
      headers: authHeader(),
    }
  );
};