import { useQuery, useQueryClient } from "react-query";
import {
  GetCustomerOrder,
  GetTableStructure,
} from "~/repositories/order.service";
import { GetInvoicePaymentHistoryTableStructure, getAllInvoicesPayments,GetInvoiceTableTableStructure,getInvoices } from "~/repositories/invoice.service";

interface IPagination {
  page: number;
  limit: number;
}

export const useOrderTableQuery = (pagination: IPagination) => {
  //const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["order-data", pagination],
    queryFn: () => GetCustomerOrder(pagination),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 2000,
    select: (data) => data.data,
  });
};

export const useOrderTableStructureQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["order-table-structure"],
    queryFn: () => GetTableStructure(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const order = queryClient.getQueryData(
        "order-table-structure"
      ) as unknown as Array<any>;
      if (order) return { data: order };
      else return undefined;
    },
  });
};

export const useInvoiceTableQuery = (pagination: IPagination) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["invoice-data", pagination],
    queryFn: () => getAllInvoicesPayments(pagination),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 2000,
    select: (data) => data,
  });
};
export const useInvoiceTableStructureQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["invoice-table-structure"],
    queryFn: () => GetInvoicePaymentHistoryTableStructure(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const order = queryClient.getQueryData(
        "invoice-table-structure"
      ) as unknown as Array<any>;
      if (order) return { data: order };
      else return undefined;
    },
  });
};
