// import * as React  from "react";
import React, { useCallback, useEffect, useMemo, useState, FC } from "react";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import {
  GetCustomerOrder,
  GetTableStructure,
} from "~/repositories/order.service";
import { 
  Typography,
  IconButton, 
  Tooltip,
  Grid,
} from "@mui/material";
import {
  useOrderTableQuery,
  useOrderTableStructureQuery,
} from "~/reactQueryHooks/useQueryOrder";
import { useNavigate } from "react-router";
import SuspenseLoader from "../SuspenseLoader";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
interface DataTable {
  onOpenTracking: (id:number)=>void;
  openModal: boolean;
}
interface IPagination {
  page: number;
  limit: number;
}
const DataTable: FC<DataTable> = ( {onOpenTracking, openModal} ) => {
  const navigate = useNavigate();

  const { isLoading: isLoadingTableStructure, data: totalRowsStructure } =
    useOrderTableStructureQuery();
  const { isLoading, data: orders, refetch } = useOrderTableQuery({
    page: 0,
    limit: 1000,
  });

  const dynamicColumns: GridColDef[] = useMemo(
    () =>
      totalRowsStructure &&
      totalRowsStructure?.length > 0 &&
      totalRowsStructure?.map((item: any) => {
        if(item.fieldalias == "Tracking"){
          return {
            field: item.fieldname,
            headerClassName: "super-app-theme--header",
            headerName: item.fieldalias,
            width: 100,
            renderCell: (params : any) => {
              return (
                <>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography 
                      sx={{
                        textTransform:"capitalize",
                        textDecoration: "underline",
                        textDecorationSkipInk: "none",
                        cursor:"pointer",
                        color:"blue",
                      }}
                      onClick={()=>{onOpenTracking(params.row.id)}}
                    >
                      {params.row.tracking}
                    </Typography>
                  </Grid>
                </Grid>
                </>
              );
            }
          };
        }if(item.fieldname == "orderstatus"){
          return {
            field: item.fieldname,
            headerClassName: "super-app-theme--header",
            headerName: item.fieldalias,
            width: 130,
            renderCell: (params : any) => {
              return (
                <>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography 
                      sx={{
                        color: params?.row?.rwcolor
                      }}
                    >
                      {params.row.finalstatus}
                    </Typography>
                  </Grid>
                </Grid>
                </>
              );
            }
          };
        }else{
          return {
            field: item.fieldname,
            headerClassName: "super-app-theme--header",
            headerName: item.fieldalias,
            width: 130,
          };
        }
      }),
    [totalRowsStructure]
  );

  useEffect(()=>{
    refetch()
  },[openModal])



  if (isLoading || isLoadingTableStructure)
    return (
      <Box
        style={{
          height: "calc(100vh - 178px)",
          background: "#FFF",
          width: "100%",
        }}
        sx={{
          "& .super-app-theme--header": {},
        }}
      >
        <SuspenseLoader />
      </Box>
    );
  else
  return (
    <Box
      style={{
        height: "calc(100vh - 178px)",
        background: "#FFF",
        width: "100%",
      }}
      sx={{
        "& .super-app-theme--header": {},
      }}
    >
      <DataGrid
        rows={orders}
        columns={dynamicColumns}
        onRowDoubleClick={(params) => navigate(`/order/${params.row.id}`)}
        rowsPerPageOptions={[10, 50, 100, 500]}
      />
    </Box>
  );
}

export default DataTable