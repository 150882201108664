import React, {
  FC,
  useState,
  createContext,
  ReactNode,
  useCallback,
} from "react";

import { useLocation, useNavigate } from "react-router";

type SearchContextProps = {
  search: any[];
  typeFilterArray: any[];
  subCategoryFilterArray: any[];
  categoryFilterArray: any[];
  onChangeSearch: (data: any) => void;
  onCatFilterChange: (data : any) => void;
  onSubCatFilterChange: (data: any) => void;
  onTypeFilterChange: (data : any) => void;
  handleFilterReset: () => void;
};

export const SearchContext = createContext<SearchContextProps>(
  {} as SearchContextProps
);

interface SearchProviderProps {
  children?: ReactNode;
}

export const SearchProvider: FC<SearchProviderProps> = ({ children }) => {
  const [search, setSearch] = useState<any>("");
  const [typeFilterArray, setTypeFilterArray] = useState<Array<string>>([]);
  const [subCategoryFilterArray, setSubCategoryFilterArray] = useState<Array<string>>([]);
  const [categoryFilterArray, setCategoryFilterArray] = useState<Array<string>>([]);

  const location = useLocation()
  const navigate = useNavigate()

  const onChangeSearch = useCallback((data: any) => {
    if(location.pathname != "/product" && data != undefined && data != ""){
      navigate("/product/1")
    } 
    setSearch(data);
  }, []);

  const onTypeFilterChange = useCallback((data: any) => {
    setTypeFilterArray(data);
  }, []);

  const onSubCatFilterChange = useCallback((data: any) => {
    setSubCategoryFilterArray(data);
  }, []);

  const onCatFilterChange = useCallback((data: any) => {
    setCategoryFilterArray(data);
  }, []);

  const handleFilterReset = useCallback(() => {
    onCatFilterChange([]);
    onSubCatFilterChange([]);
    onTypeFilterChange([]);
    onChangeSearch("")
  }, []);

  return (
    <SearchContext.Provider
      value={{
        search,
        typeFilterArray,
        subCategoryFilterArray,
        categoryFilterArray,
        onChangeSearch,
        onSubCatFilterChange,
        onTypeFilterChange,
        onCatFilterChange,
        handleFilterReset,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
