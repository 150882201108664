import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "~/config/axios";

export const getAllUnPaidInvoices = ({ page, limit }: { page: any; limit: any }) => {
    return axios.post(
      API_URL + `/CustomerOrder/searchforinvoicepayment`,
      {
        pageSize: limit,
        pageNumber: page,
      },
      { headers: authHeader() }
    );
  };

  export const GetInvoicePaymentTableStructure = async () => {
    const res = await axios.get(
      API_URL + `/CustomerOrder/DisplayFieldinvoicepayment`,
      {
        headers: authHeader(),
      }
    );
  
    return res.data;
  };

  export const GetInvoice = async (id : number) => {
    return await axios.get(
      API_URL + `/CustomerOrder/GetOrderInvoicePaymentDataById?id=${id}`,
      {
        headers: authHeader(),
      }
    );
  };

  export const MakeOrderPaymentInvoice = async (data : any) => {
    const res = await axios.put(
      API_URL + `/CustomerOrder/MakeOrderPaymentInvoice`, data,
      {
        headers: authHeader(),
      }
    );
  
    return res.data;
  };

  export const getAllInvoicesPayments = async ({ page, limit }: { page: any; limit: any }) => {
    const res = await axios.post(
      API_URL + `/CustomerOrder/searchorderinvoicepayment`,
      {
        pageSize: limit,
        pageNumber: page,
      },
      { headers: authHeader() }
    );
    return res.data;
  };

  export const GetInvoicePaymentHistoryTableStructure = async () => {
    const res = await axios.get(
      API_URL + `/CustomerOrder/DisplayFieldorderinvoicepayment`,
      {
        headers: authHeader(),
      }
    );
  
    return res.data;
  };

  export const GetInvoiceTableTableStructure = async () => {
    return await axios.get(
      API_URL + `/OrderLevel/DisplayFieldAliasSearchCustInvoice`,
      {
        headers: authHeader(),
      }
    );

  };

  export const getInvoices = () => {
    return axios.post(
      API_URL + `/OrderLevel/SearchInvoiceCustomer`,
      {
        pageSize: 999999,
        pageNumber: 1,
        search: []
      },
      { headers: authHeader() }
    );
  };