import { useQuery, useQueryClient } from "react-query";
import { getAllTypes } from "~/repositories/productType.service";
import {
  GetAllCategoies,
  GetAllSubCategoies,
} from "~/repositories/ProductCategory.service";
import { getManufacturers } from "~/repositories/customerOrder.service";

export const useManufacturerQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["manufacturers"],
    queryFn: () => getManufacturers(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const manufacturer = queryClient.getQueryData(
        "manufacturers"
      ) as unknown as Array<any>;
      if (manufacturer && manufacturer?.length > 0) return { data: manufacturer };
      else return undefined;
    },
  });
};

export const useProductsTypeQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["product-type"],
    queryFn: () => getAllTypes(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const type = queryClient.getQueryData(
        "product-type"
      ) as unknown as Array<any>;
      if (type && type?.length > 0) return { data: type };
      else return undefined;
    },
  });
};

export const useProductsCategoryQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["product-category"],
    queryFn: () => GetAllCategoies(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const category = queryClient.getQueryData(
        "product-category"
      ) as unknown as Array<any>;
      if (category && category?.length > 0) return { data: category };
      else return undefined;
    },
  });
};
export const useProductsSubCategoryQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["product-sub-category"],
    queryFn: () => GetAllSubCategoies(),
    refetchOnWindowFocus: false,
    initialData: () => {
      const category = queryClient.getQueryData(
        "product-sub-category"
      ) as unknown as Array<any>;
      if (category && category?.length > 0) return { data: category };
      else return undefined;
    },
  });
};
