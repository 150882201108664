/**
 * 
 * Handles the checkout proccess steps
 * 
 */
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Card, Box, Button, styled, Grid } from "@mui/material";
import { Create as CreateOrder } from "~/repositories/order.service";
import { CartLayout } from "~/pages/CheckoutPage/Card";
import { StyledCart } from "~/pages/CheckoutPage/Card/CartProduct";
import PatientPage from "~/pages/CheckoutPage/Card/Patient/index";
import ShippingPage from "~/pages/CheckoutPage/Card/Shipping/index";
import BillingPage from "~/pages/CheckoutPage/Card/Billing";
import PaymentPage from "~/pages/CheckoutPage/Card/PayementPage";
import { defaultOrder, ShopContext } from "~/contexts/ShopContext";
import { AuthContext } from "~/contexts/authContext";
import Toast from "../Notification";
import { useNavigate } from "react-router";
import FinishCheckout from "~/pages/CheckoutPage/Card/FinishCheckout";
import { deleteCustomerCartProduct } from "~/repositories/customerOrder.service";
import { ReviewOrder } from "~/pages/CheckoutPage/Card/ReviewOrder/index";
import { MakeOrderPayment } from "~/repositories/customerOrder.service";
import { SearchContext } from "~/contexts/SearchContext";

import {
  useProductsQuery,
} from "~/reactQueryHooks/useQueryProducts";

const steps = [
  "Cart",
  "Shipping",
  "Review",
  "Payment"
];

const RHFStrepper: FC<any> = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [orderId, setOrderId] = useState<number>(0);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const navigate = useNavigate();

  const { search } = React.useContext(SearchContext);
  const { user } = useContext(AuthContext);

  const {refetch} = useProductsQuery(user, search);
  
  const { products, order, onChangeData, onChangeOrder } =
    useContext(ShopContext);

  const subTotal = React.useMemo(() => {
    if(products){
      return products
        ? products.reduce((prev, curr) => {
            const price = curr.customerprice;
            return prev + price * curr.quantity;
          }, 0)
        : 0;
    }else{
      return 0
    }
  }, [products]);

  const tax = React.useMemo(() => {
    const amount = order.taxpercent * subTotal
    return amount
  }, [subTotal,order]);  

  const selectedProducts: any = React.useMemo(() => {
    return products?.filter((product) => product.quantity > 0);
  }, [products]);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const setStep = useCallback((step : number) => {
    setActiveStep(step);
  }, []);

  const handleReset = () => {
    navigate("/order");
  };

  const handleErrorReset = () => {
    navigate("/order/"+orderId);
  };

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setErrorMessage("")
    setIsOpen(false);
  }, []);


  const onHandleOrderCreation = useCallback(async () => {
    setSubmitting(true)
    const data = {
      ordernumber: "-",
      customerid: user?.customerid,
      billto_id: order?.billto_id,
      paytype: 2,
      // billto_id: 14,
      shipto_id: order?.shipto_id,
      shiptypeid: order?.shiptypeid,
      totalamount: order?.totalamount,
      salesrepid: order?.salesrepid,
      discount: order?.discount,
      //shippingamount: order?.shippingamount,
      shippingamount: order?.shippingamount,
      taxamount: order?.taxamount,
      netamount: order?.netamount,
      ponumber: order?.ponumber,
      orderSubItems: selectedProducts.map((product: any) => ({
        id: product.productid,
        orderid: 0,
        productid: product.productid,
        productsubid: product.productsubid,
        type: product.type,
        qty: product.quantity,
        price:
          product.pricetype === "Default"
            ? product.customerprice
            : product.customerprice,
        amount:
          product.pricetype === "Default"
            ? product.customerprice * product.quantity
            : product.customerprice * product.quantity,
      })),
    };
    await CreateOrder(data).then(
      async (response) => {
        refetch()
        setOrderId(response.data.data[0].orderid);
        handleNext();
        handleOpen();
        selectedProducts?.map(async (product: any) => {
          await deleteCustomerCartProduct({
            productid: product.productid,
            productsubid: product.productsubid,
            qty: 1,
          });
        });
        onChangeData([]);
        onChangeOrder(defaultOrder);
        setSubmitting(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      },
      (error) => {
        setError(true);
        handleOpen();
        console.log("error", error);
        setSubmitting(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNext, handleOpen, order, user?.customerid]);

  const onPayment = useCallback(async (data : any) => {
     let ponumber = "";
    if(data.ponumber){
      ponumber = data.ponumber
    }else if(order?.ponumber){
      ponumber = order?.ponumber
    }
    setSubmitting(true)
	
	// Transform totalamount to a number
    let paymentamount = Number(order?.totalamount);
    // If paymentamount is NaN, fallback to 0 or some default value
    if (isNaN(paymentamount)) {
      paymentamount = 0; // or handle accordingly
    } else {
      paymentamount = Number(paymentamount.toFixed(2)); // Ensure two decimal places
    }
	
    const orderdata = {
      ordernumber: "-",
      customerid: user?.customerid,
      billto_id: order?.billto_id,
      paytype: 1,
      shipto_id: order?.shipto_id,
      shiptypeid: order?.shiptypeid,
      totalamount: order?.totalamount,
      salesrepid: order?.salesrepid,
      discount: order?.discount,
      shippingamount: order?.shippingamount,
      taxamount: order?.taxamount,
      netamount: order?.netamount,
      ponumber: ponumber,
      orderSubItems: selectedProducts.map((product: any) => ({
        id: product.productid,
        orderid: 0,
        productid: product.productid,
        productsubid: product.productsubid,
        type: product.type,
        qty: product.quantity,
        price:
          product.pricetype === "Default"
            ? product.customerprice
            : product.customerprice,
        amount:
          product.pricetype === "Default"
            ? product.customerprice * product.quantity
            : product.customerprice * product.quantity,
      })),
    };
    await CreateOrder(orderdata).then(
      async (response) => {
        const date = data.expdate.split('/');
        const paymentdata = {
          id: 0,
          orderid: response.data.data[0].orderid.toString(),
          amount: paymentamount,
          paymenttermsid: 1,
          customerpaymentid: 0,
          name: data.name,
          cc: data.cc,
          expirymonth: date[0],
          expiryyear: date[1],
          cvv: data.cvv.toString(),
          bankname: "-",
          chequeno: "-",
          chequedate: new Date().toISOString().slice(0, 10),
          remarks: "-",
          ccaddress: data.ccaddress,
          ccstate: data.ccstate,
          cccity: data.cccity,
          cczipcode: data.cczipcode,
          cccountry: "US",
        }
        await MakeOrderPayment(paymentdata).then(
          async(response)=>{
            if(response.data?.statusCode == 404){
              setErrorMessage(response.data.message)
              console.log("error", error);
              setIsError(true)
              setSubmitting(false)
              setOrderId(parseInt(paymentdata.orderid));
              handleNext();
              selectedProducts?.map(async (product: any) => {
                await deleteCustomerCartProduct({
                  productid: product.productid,
                  productsubid: product.productsubid,
                  qty: 1,
                });
              });
              onChangeData([]);
              onChangeOrder(defaultOrder);  
              setSubmitting(false)
            }else{
              refetch();
              setOrderId(parseInt(paymentdata.orderid));
              handleNext();
              handleOpen();
              selectedProducts?.map(async (product: any) => {
                await deleteCustomerCartProduct({
                  productid: product.productid,
                  productsubid: product.productsubid,
                  qty: 1,
                });
              });
              onChangeData([]);
              onChangeOrder(defaultOrder);  
              setSubmitting(false)
            } 
          },
          (error)=>{
            setError(true);
            handleOpen();
            console.log("error", error);
            setSubmitting(false)
          }
        )
      },
      (error) => {
        setError(true);
        handleOpen();
        console.log("error", error);
        setSubmitting(false)
      }
    );  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNext, handleOpen, order, user?.customerid, subTotal]);

  return (
    <>
      <Toast
        open={isOpen}
        message={
          errorMessage?
            `${errorMessage}`
            : error? "Please check your order and try again"
            : `Your Order With Number ${orderId} Has Been Created!`
        }
        onClose={handleClose}
        severity={error ? "error" : "success"}
      />
      <StyledChekoutPage>
        <CardStyled>
          {activeStep >= steps.length ? (
            <React.Fragment>
              <ContainerCard sx={{ p: 0, height: "100%" }}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FinishCheckout errorMsg={errorMessage} isError={isError} orderId={orderId} HandleReset={handleReset} HandleErrorReset={handleErrorReset}/>
                </Box>
              </ContainerCard>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid
                sx={{ overflow: "hidden", margin: 0, width: "100%" }}
                flex={1}
                container
              >
                <Grid sx={{ height: "100%", width: "100%" }} item>
                  <ContainerCard sx={{ height: "100%", width: "100%" }}>
                    {activeStep === 0 ? (
                      <CartLayout
                        handleBack={setStep}
                        activeStep={activeStep}
                        title="Shopping Cart"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CheckoutButton
                              disabled={
                                activeStep === 0 && products?.length === 0
                              }
                              onClick={handleNext}
                              sx={{ p: 1, fontSize: "24px" }}
                            >
                              {"PROCEED TO SHIPPING"}
                            </CheckoutButton>
                          </Box>
                        }
                      >
                        <StyledCart />
                      </CartLayout>
                    ) : activeStep === 1 ? (
                      /* user.isallowaddpatient?
                      <CartLayout
                        title="Patient Details"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              width="100%"
                            >
                              <CheckoutButton
                                disabled={
                                  activeStep === 1 && order.shipto_id === 0
                                }
                                onClick={handleNext}
                                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                              >
                                REVIEW BILLING
                              </CheckoutButton>
                              <SecondButton
                                onClick={handleBack}
                                sx={{ p: 1, fontSize: "24px" }}
                              >
                                UPDATE CART
                              </SecondButton>
                            </Box>
                          </Box>
                        }
                      >
                        <PatientPage />
                      </CartLayout>
                    : */
                      <CartLayout
                        handleBack={setStep}
                        activeStep={activeStep}
                        title="Shipping Details"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              width="100%"
                            >
                              <CheckoutButton
                                disabled={
                                  activeStep === 1 && (order.shipto_id === 0 || order.billto_id === 0)
                                }
                                onClick={handleNext}
                                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                              >
                                Review order
                              </CheckoutButton>
                              <SecondButton
                                onClick={handleBack}
                                sx={{ p: 1, fontSize: "24px" }}
                              >
                                Back to Cart
                              </SecondButton>
                            </Box>
                          </Box>
                        }
                      >
                        <ShippingPage />
                      </CartLayout>
                    
                    /*)  : activeStep === 2 ? (
                      <CartLayout
                        title="Billing Details"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              width="100%"
                            >
                              <CheckoutButton
                                disabled={
                                  activeStep === 2 && order.billto_id === 0
                                }
                                onClick={handleNext}
                                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                              >
                                Review order
                              </CheckoutButton>
                              <SecondButton
                                onClick={handleBack}
                                sx={{ p: 1, fontSize: "24px" }}
                              >
                                {user.isallowaddpatient? "Update patient" : "Update shipping"}
                              </SecondButton>
                            </Box>
                          </Box>
                        }
                      >
                        <BillingPage />
                      </CartLayout> */
                    ) : activeStep === 2 ? (
                      <CartLayout
                        handleBack={setStep}
                        activeStep={activeStep}
                        title="Order Review"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              width="100%"
                            >
                              <CheckoutButton
                                onClick={user.isshowpayment? handleNext : onHandleOrderCreation}
                                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                              >
                                {user.isshowpayment? "Payment" : "Confirm Order"}
                              </CheckoutButton>
                              <SecondButton
                                onClick={handleBack}
                                sx={{ p: 1, fontSize: "24px" }}
                              >
                                Back to Shipping
                              </SecondButton>
                            </Box>
                          </Box>
                        }
                      >
                        <ReviewOrder defaultProduct={products} />
                      </CartLayout>
                    ) : (activeStep === 3 && user.isshowpayment)? (
                      <CartLayout
                      activeStep={activeStep}
                        handleBack={setStep}
                        title="Payment"
                        buttons={
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="10px"
                              width="100%"
                            >
                              {/* <CheckoutButton
                                onClick={onHandleOrderCreation}
                                sx={{ p: 1, fontSize: "24px", mt: "10px" }}
                              >
                                Confirm Order
                              </CheckoutButton> */}
                              <SecondButton
                                onClick={handleBack}
                                sx={{ p: 1, fontSize: "24px" }}
                              >
                                Back to Order Review
                              </SecondButton>
                            </Box>
                          </Box>
                        }
                      >
                        <PaymentPage 
                          onPayment={onPayment}
                          onInvoice={onHandleOrderCreation}
                          isSubmitting={isSubmitting}
                        />
                      </CartLayout>
                    ) : null}
                  </ContainerCard>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </CardStyled>
      </StyledChekoutPage>
    </>
  );
};

const CheckoutButton = styled(Button)(
  () => `
    background: #1CB7EC;
    border-radius: 0;
    font-size: 18px !important;
    font-weight: 400;
    height: 44px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    gap: 5px;

    :hover {
      background: #5569ff;
      color: #FFF;
    }

`
);

const SecondButton = styled(Button)(
  () => `
    background: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px !important;
    font-weight: 400;
    height: 44px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    gap: 5px;
    :hover {
      background: #ddd;
      color: #000;
    }

`
);

const StyledChekoutPage = styled("div")(
  () => `
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block; 
    height: 100%;
`
);
const ContainerCard = styled(Card)(
  () => `
    display:flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    background-color: transparent;
`
);

const CardStyled = styled(Card)(
  () => `
    background-color: transparent;
    && {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: unset;
      border-radius:0;
    }
`
);

export default RHFStrepper;